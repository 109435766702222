import { data } from "autoprefixer";
import { db } from "../firebase";

class ReferenceService {
  getRefferances = async () => {
    const snapshot = await db
      .collection("references").orderBy('date')
      .get(); 
    return snapshot.docs.map((doc) => doc.data()).reverse(); 
  }; 
}

export default ReferenceService = new ReferenceService();
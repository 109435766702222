import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link as ScrollLink } from "react-scroll";
import content from "../content";

const Contact = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <div
      className="min-h-screen flex justify-center items-center relative"
      style={{ backgroundColor: "#091c29" }}
      id="mycontact"
    >
      <div
        className="w-full md:w-4/5 flex flex-col md:flex-row-reverse  justify-around items-center md:rounded-xl border m-5"
        style={{ backgroundColor: "#091c29" }}
      >
        <div
          className={`${
            animated ? "" : "translate-y-20 opacity-0"
          } transform transition duration-3000 text-white   relative font-dosis w-4/5 md:w-2/5 mt-5`}
        >
          <h1 className="font-bold text-3xl text-center md:text-start  animate-pulse1s">
            {content.contact.title}
          </h1>
          <p className="text-2xl mt-5">{content.contact.desc}</p> 
          <div className="w-11/12  flex flex-wrap md:justify-start   justify-center mt-5 md:mt-0">
            <div className="flex flex-col items-center"> 
              <div className="flex justify-center  flex-wrap md:flex-nowrap">
               
                {content.contact.socials.map((sosmed, index) => (
                  <a key={index} href={`${sosmed.url}`} className="mx-4 my-5">
                    <span>
                      <img
                        src={sosmed.img}
                        alt={sosmed.alt} 
                        width="50px" 
                        className={`${
                          index % 2 === 0
                            ? "animate-newbounce"
                            : "animate-rebounce"
                        }`}
                      />
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-90">
          <img
            src={content.contact.img} 
            alt="" 
            width="250px"
            className="my-5 mx-auto  transition duration-3000 ease-in-out opacity-50"
          />
        </div>
      </div>
      <ScrollLink to="home" smooth={true}>
        <button className="absolute right-5 bottom-2 font-bold text-white md:text-white font-dosis px-1 border-b-2 border-transparent hover:text-indigo-700 hover:border-indigo-500">
          Back to Top
        </button>
      </ScrollLink>
    </div>
  );
};

export default Contact;

import { useEffect, useRef } from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Project from "./components/Project";
// import Stack from "./components/Stack";
import Work from "./components/Work";
import visitorInformation, { inputVisitor, validateVisitorTime } from "./content/visitorInformation";
import References from "./components/References";
import { VisitorService } from "./services/VisitorService";

function App() {
  const dataFetchedRef = useRef(false);
  const fetchVisitor =async () => {
    console.log('Fetching data...');
    
    const visitor = await visitorInformation()
    console.log(visitor) 
    VisitorService.create(visitor);
}
  useEffect(() => {
    //  function fetchVisitor() {
    //   // if (validateVisitorTime()) {
    //   //   inputVisitor('https://ories.goes2nobel.com/dbmysql/visitor-portfolio-input.php', visitor)
    //   //     .then(res => {
    //   //       console.log(res) 
    //   //     })
    //   // }
    // }
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchVisitor()
  }, [])

  return (
    <div className="w-full overflow-x-hidden">
      <Navigation />
      <Header />
      <About /> 
      <Project />
      <References />
      <Contact />
    </div>
  );
}

export default App;
import content from "."

const imgPath = `${process.env.PUBLIC_URL}/assets/project`

const projectContent = [
    {
        title: 'PT Nutech Integrasi',
        desc: content.work.desc,
        projects: [ 
            {
                img_thumb: imgPath + '/hirdavatbulurum.png',
                title: 'Hırdavatbulurum',
                short_desc: 'E-Ticaret sipariş takip mobil uygulaması',
                long_desc: '',
                tools_tech: ['Android','IOS', 'Flutter', 'Firebase', 'Notification','GetX','Vexana','MVC', 'Figma'],
                private: true
            },
            {
                img_thumb: imgPath + '/sunmi.png',
                title: 'Özel Uygulama',
                short_desc: 'Sunmi el terminali için tasarlanan bir otopark projesidir. Araç giriş çıkışları yapılmaktadır ve fiş çıkartılmaktadır. Bütün kayıtları çevrimiçi ve çevrim dışı kaydederek ciro hesabı yapmaktadır. Abonelik sistemi mevcuttur. Proje mobil uygulamadan ve web admin panelden oluşmaktadır.',
                long_desc: '',
                tools_tech: ['Android', 'Flutter', 'GetX', 'Hive', 'Vexana','MVC','Firebase','QR','Sunmi','Printer','React', 'Redux', 'Tailwind', 'Figma'],
                private: true
            },
            {
                img_thumb: imgPath + '/jineonkoloji.png',
                title: 'JineOnkolojik Destek',
                short_desc: 'Jineonkolojik kanserler ve uygulanan tedavilerin yan etkileri ile baş etmeyi kolaylaştırmak için geliştirilmiştir. Bilgiler, Tedaviler, Öyküler vb. bölümlerden oluşmaktadır. Mobil uygulama içerisinde uzman akademisyenler ile iletişime geçmek için mesajlaşma ekranı ve anketler bulunmaktadır.',
                long_desc: '',
                tools_tech: ['Android', 'Flutter', 'GetX', 'Vexana', 'Firebase', 'MVC','Notification', 'Strapi', 'Heroku','Cloudinary'],
                private: true
            },
            {
                img_thumb: imgPath + '/cly.png',
                title: 'Clysterum',
                short_desc: 'Bir kripto para birimi ve sağlık hizmeti projesidir. İçerisinde Yürü Kazan, Acil Kan, Nöbetçi Eczane, En Hakın Hastane ve Eczane, İlaç Hatırlatıcı özellikleri bulunan mobil uygulama Android ve IOS için geliştirildi. 4 kişilik bir ekip tarafından geliştirilen uygulamada fronted developer olarak görev aldım. ',
                long_desc: '',
                tools_tech: ['Android','IOS', 'Flutter', ],
                private: true
            },
            {
                img_thumb: imgPath + '/e-message.png',
                title: 'e-Message',
                short_desc: 'Çevrimiçi sohbet, gönderi paylaşımı, beğeni, yorum özellikleri vb bir çok özelliği bulunan bir sosyal medya mobil uygulamasıdır. ••• Kişisel proje •••',
                long_desc: '',
                tools_tech: ['Android', 'Flutter', 'GetX', 'Firebase', 'MVC','Notification'],
                private: true
            },
            {
                img_thumb: imgPath + '/tdk.png',
                title: 'Özel Uygulama',
                short_desc: 'Üretim süreci takibininin yapıldığı, personel mesai kayıtlarının tutulduğu ve bunların analizinin yapıldığı bir erp projesidir. Mobil uygulama ve web admin panelden oluşmaktadır. Telefon, tablet ve web olmak üzere 3 platform için geliştirilmiştir. ',
                long_desc: '',
                tools_tech: ['Figma','Android','Flutter','Mvc', 'GetX', 'Hive', 'Html', 'Css', 'JS', 'React', 'Redux', 'Router', 'Tailwind', 'Material UI', 'ChartJS', 'NodeJS','Firebase','MongoDB','JWT','SocketIO','AWS','EC2','S3','Docker','Nginx','Route53'],
                private: true
            },
            {
                img_thumb: imgPath + '/akgn.png',
                title: 'Özel Uygulama',
                short_desc: 'Özel bir erp uygulamasıdır. Mobil uygulamanın içerisinde araç takibi, yıllık izin takibi, yemekhane, duyuru, anket, toplantı, rehber ve profil menüleri bulunmaktadır. ',
                long_desc: '',
                tools_tech: ['Android','IOS', 'Flutter', 'MVC', 'GetX', 'Hive', 'Firebase', 'Notification',],
                private: true
            },
        ]
    }, 
]

export default projectContent
import { db } from "../firebase";
import { getFirestore } from "firebase/firestore";

class VisitorServices {
  collection;

  constructor(collectionName) {
    this.collection = db.collection(collectionName);
  }

  create = async (data) => { 
    return await this.collection.add(data);
  };
  getVisitorsCount = async () => { 
    const snapshot = await  this.collection.get(); 
    return snapshot.docs.length;
  };
}

export const VisitorService = new VisitorServices("visitors");

import { LazyLoadImage } from 'react-lazy-load-image-component'
import content from '../content'
import { FaDesktop, FaDatabase, FaTools } from "react-icons/fa";

const About = () => {
    return (
        <div className='min-h-screen flex flex-col-reverse md:flex-row justify-evenly items-center font-dosis bg-soft py-10' id='about' > 
            <div className='w-11/12 md:w-2/5 flex flex-wrap justify-center mt-10 md:mt-0'>
               <div className='flex flex-col items-center'>
               {/* <div className='text-2xl font-bold  '>Kullandığım teknolojiler</div> */}
                <div className='flex justify-center  flex-wrap'>  {content.stack.tech.map((tech, index) => (
                    <div className='flex flex-col items-center'><span key={index} 
                    className={`w-24 h-24  mx-4 my-2 p-6 bg-soft shadow  hover:bg-slate-50 flex justify-center items-center rounded-full cursor-pointer`}>
                        <img  src={tech.img} alt={tech.alt} />
                    </span>
                    <div className='text-lg text-slate-500'>
                    {tech.alt}
                    </div></div>
                ))}</div>
                </div>
            </div>
            <div className='w-5/6 md:w-2/5 mt-10'>
                <h1 className='text-4xl font-bold'>Hakkımda</h1>
                <h2 className=' mt-3 text-3xl font-bold'>Can KURT</h2>
                <p className='mt-5 w-11/12 md:max-w-xl text-lg md:text-xl'>{content.stack.desc}.</p>
                <div className='flex flex-row items-center font-bold m-3' style={{color: '#091c29'}}>
                    <span><FaDesktop size={22} /></span>
                    <p className='ml-3 text-xl'>Frontend</p>
                </div>
                <div className='flex flex-row items-center font-bold m-3' style={{color: '#091c29'}}>
                    <span><FaDatabase size={22} /></span>
                    <p className='ml-3 text-xl'>Backend</p>
                </div>
                <div className='flex flex-row items-center font-bold m-3' style={{color: '#091c29'}}>
                    <span><FaTools size={22} /></span>
                    <p className='ml-3 text-xl'>Other</p>
                </div>
            </div>
        </div>
    )
}

export default About
import React, { useEffect, useState } from "react";
import Typical from "react-typical";
import { LazyLoadImage } from "react-lazy-load-image-component";
import content from "../content";
import { Link as ScrollLink } from "react-scroll";
import useStartAnimation from "../hooks/useStartAnimation";
import { VisitorService } from "../services/VisitorService";

const Header = () => {
  const animated = useStartAnimation();
  return (
    <div
      className="min-h-screen flex justify-center items-center"
      id="home"
      style={{ backgroundColor: "#091c29" }}
    >
      <div className="w-10/12 mx-auto flex flex-col md:flex-row-reverse justify-around items-center ">
        <div className="w-full scale-90 md:w-2/6   md:border-solid items-end flex rounded-xl  opacity-85 ">
          <img
            effect="blur"
            src={content.header.img}
            alt=""
            placeholderSrc={content.header.imgPlaceholder}
            className="w-full mx-auto flex rounded-xl"
          />
        </div>
        <div className="text-white font-dosis text-center md:text-left">
          <h2
            className={`${
              animated ? "" : "translate-y-10 opacity-0"
            } transform transition duration-2000 text-3xl md:text-4xl lg:text-6xl font-bold`}
          >
            {content.header.text[0]}
            <br />
            {content.header.text[1]}
          </h2>
          <h1
            className={`${
              animated ? "" : "translate-y-20 opacity-0"
            } transform transition duration-2000 text-2xl md:text-4xl text-gray-300 md:mt-5`}
          >
            {content.header.text[2]}{" "}
            <Typical
              className="inline-block"
              steps={content.header.typical}
              loop={Infinity}
            />
          </h1>
          <div className="max-w-4xl p-4 text-gray-800 bg-transparent  border-gray-400 rounded-lg  mt-10">
            <div className="mb-2">
              <div className="h-3 text-3xl text-left text-gray-300">“</div>
              <p className="px-4 text-2xl text-center text-gray-400">
                {/* Başarısız olmam önemli değil. En azından başkalarına örnek
                olabilmişimdir. Ben başarısız olsam bile birisi mutlaka
                başaracaktır. */}
                Bugün sahip olduğunuz en büyük zenginlik, iki kulağınızın
                arasındadır.
              </p>
              <p className="px-4 text-base text-right text-gray-400">
                Brian Tracy
              </p>
              <div className="h-3 text-3xl text-right text-gray-300">”</div>
            </div>
          </div>
          <div
            className={`${
              animated ? "" : "translate-y-20 opacity-0"
            }  pt-8 transform transition duration-3000 text-white font-dosis w-4/5 md:w-2/5 mt-5`}
          >
            {/* <div className=" text-lg ">Contact Me</div> */}
            {/* <div className="flex justify-start ">
              {content.contact.socials.map((sosmed, index) => (
                <a key={index} href={`${sosmed.url}`} className="mx-2 my-5">
                  <span>
                    <img
                      src={sosmed.img}
                      alt={sosmed.alt} 
                      width="50px"
                      placeholderSrc={content.contact.socials[2].img}
                      className={`${
                        index % 2 === 0
                          ? "animate-newbounce"
                          : "animate-rebounce"
                      }`}
                    />
                  </span>
                </a>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import { Navigation, Pagination, A11y, EffectCards, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import content from "../content";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReferenceService from "../services/ReferenceService";

const References = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [data, setData] = useState(null);
  const handleResize = () => {
    if (window.innerWidth < 720) { 
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function getData() {
      const data = await ReferenceService.getRefferances();
      // console.log(data);
      setData(data);
    }
    getData();
  }, []);
  return (
    <div
      className="min-h-screen flex flex-col-reverse md:flex-row justify-evenly items-center font-dosis bg-soft py-10"
      id="references"
    >
      <div className=" w-full md:w-4/5 mt-20  px-4 flex flex-col ">
        <h1 className="text-3xl font-bold flex justify-center">Referanslar</h1>

        <div className=" flex justify-center">
          <Swiper
            className="flex justify-center  "
            modules={[Pagination, Autoplay, Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            // grabCursor={true}
            // effect={"cards"}
            centeredSlides={true}
            navigation={isMobile ? false : true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true, dynamicBullets: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {data &&
              data.map((v) => (
                <SwiperSlide className="w-full flex flex-col items-center py-20 gap-y-1  rounded-xl justify-center select-none">
                  <img
                    src={v.img || process.env.PUBLIC_URL + '/assets/referenceImg.png'}
                    placeholderSrc={content.contact.imgPlaceholder}
                    effect="blur"
                    style={{ width: 150, height: 150, borderRadius: 400 / 2 }}
                    className={`object-cover mb-6 ${v.img && "shadow-lg"}`}
                  />
                  <div className="text-lg font-bold mb-1">{v.name}</div>
                  {v.profession && <div className="font-medium text-gray-700 mb-1">{v.profession}</div>}
                 {v.project &&  <div className="text-blue-500 font-medium   mb-1">
                  {v.project}
                  </div>}
                  <p
                    className={`text-gray-500 mb-6  text-center pt-3 ${
                      isMobile || "px-32"
                    }`}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="quote-left"
                      className="w-6 pr-2 inline-block"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                      ></path>
                    </svg>
                    {v.text}
                  </p>
                </SwiperSlide>
                
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default References;
